import React from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'

import styles from "./guides.module.css"
import { Link } from 'react-router-dom';


function GalleryPage() {
  return (
    <div className={styles.content_column}>
      <ImageCarousel />

      <h2 className={styles.title}>Lucent Heart Guides</h2>
      <div className='button_info'>

        <div className={styles.disclaimer}>
          <p className={styles.overlock_Font}>
          These guides have been compiled over the years by players and we've organized them here for your convenience. 
          You can access the guides by clicking the links below:</p>
          
          <div className={styles.column_group}>
          <div>

          <div className={styles.overlock_Font2}>Crafting Quests
          <ul>
          <li className={styles.overlock_Font}><Link to='/guides/blacksmith'>Blacksmith</Link></li>
          <li className={styles.overlock_Font}><Link to='/guides/tailor'>Tailor</Link></li>
          <li className={styles.overlock_Font}><Link to='/guides/jeweler'>Jeweler</Link></li>
          <li className={styles.overlock_Font}><Link to='/guides/mechanic'>Mechanic</Link></li>
          <li className={styles.overlock_Font}><Link to='/guides/alchemist'>Alchemist</Link></li>
          <li className={styles.overlock_Font}><Link to='/guides/card-designer'>Card Designer</Link></li>
          </ul>
          </div>

          <div className={styles.overlock_Font2}>General Guides
          <ul>
          {/*<li className={styles.overlock_Font}>Character Creation</li>
          <li className={styles.overlock_Font}>Cupid System</li>
          <li className={styles.overlock_Font}>Crafting System</li>
          <li className={styles.overlock_Font}>Dance System</li>
          <li className={styles.overlock_Font}>Race System</li> */}
          <li className={styles.overlock_Font}><Link to='/guides/eventure-system'>Eventure System</Link></li>
          {/*<li className={styles.overlock_Font}>House System</li>
          <li className={styles.overlock_Font}>Faith System</li>
          <li className={styles.overlock_Font}>Zodiac System</li>
          <li className={styles.overlock_Font}>Class Tree</li>
          <li className={styles.overlock_Font}>Pets</li>
          <li className={styles.overlock_Font}>Mounts</li>
          <li className={styles.overlock_Font}>Knowledge Master</li>
          <li className={styles.overlock_Font}>Dungeons</li>
          <li className={styles.overlock_Font}>Guilds</li>
          <li className={styles.overlock_Font}>Survival Arena</li>
          <li className={styles.overlock_Font}>PvP & GvG</li> */}
          </ul>
          </div>
          
           {/*<div className={styles.overlock_Font2}>Miscellaneous
          <ul>
          <li className={styles.overlock_Font}>Cupid Dungeons</li>
          <li className={styles.overlock_Font}>Daily Quests & Rewards</li>
          <li className={styles.overlock_Font}>Online Time Rewards</li>
          <li className={styles.overlock_Font}>Starry Sky Treasure Gift Boxes</li>
          </ul>
          </div>*/}


          {/*<div className={styles.overlock_Font2}>Crafting Quests
          <ul>
          <li className={styles.overlock_Font}>Blacksmith</li>
          <li className={styles.overlock_Font}>Tailor</li>
          <li className={styles.overlock_Font}>Jeweler</li>
          <li className={styles.overlock_Font}>Mechanic</li>
          <li className={styles.overlock_Font}>Alchemist</li>
          <li className={styles.overlock_Font}>Card Designer</li>
          </ul>
        
        
          Class Tree
          <ul>
          <li className={styles.overlock_Font}>Dawn Prophet</li>
          <li className={styles.overlock_Font}>Sun Commissioner</li>
          <li className={styles.overlock_Font}>Moon Flame Envoy</li>
          <li className={styles.overlock_Font}>Galaxy Sage</li>
          <li className={styles.overlock_Font}>Solar Guardian</li>
          <li className={styles.overlock_Font}>Celestial Templar</li>
          <li className={styles.overlock_Font}>Comet Marksman</li>
          <li className={styles.overlock_Font}>Nova Sentinel</li>
          <li className={styles.overlock_Font}>Phantom Warlock</li>
          <li className={styles.overlock_Font}>Ascetic</li>
          </ul>
</div>*/}
          </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default GalleryPage